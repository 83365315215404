html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Nunito', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.App {
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 130px 0 0;
}

.Toastify {
  z-index: 12001;
}