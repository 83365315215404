@font-face {
  font-family: 'Nunito';
  src: local('Nunito Light'), local('Nunito-Light'),
    url('Nunitolight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito Regular'), local('Nunito-Regular'),
    url('Nunitoregular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito SemiBold'), local('Nunito-SemiBold'),
    url('NunitosemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito Italic'), local('Nunito-Italic'),
    url('Nunitoitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito ExtraLight Italic'), local('Nunito-ExtraLightItalic'),
    url('NunitoextraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito ExtraBold Italic'), local('Nunito-ExtraBoldItalic'),
    url('NunitoextraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito ExtraBold'), local('Nunito-ExtraBold'),
    url('NunitoextraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito Light Italic'), local('Nunito-LightItalic'),
    url('NunitolightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito Black'), local('Nunito-Black'),
    url('Nunitoblack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito ExtraLight'), local('Nunito-ExtraLight'),
    url('NunitoextraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito Bold Italic'), local('Nunito-BoldItalic'),
    url('NunitoboldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito SemiBold Italic'), local('Nunito-SemiBoldItalic'),
    url('NunitosemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito Bold'), local('Nunito-Bold'),
    url('Nunitobold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito Black Italic'), local('Nunito-BlackItalic'),
    url('NunitoblackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
